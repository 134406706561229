/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import Layout from '../layouts/Layout';
import documentationJson from '../textdata/editorDocumentation.json';
import SideBarDocumentation from '../components/documentationComponents/NavigationDocumentation';
import ContentDocumentation from '../components/documentationComponents/RenderDocumentation';

const useStyles = makeStyles((theme) => ({
  navColumn: {
    width: '20% !important',
    backgroundColor: 'rgba(237, 242, 252, 1)',
    position: 'relative',
    [theme.breakpoints.down('xl')] : {
      width: '30% !important',
    },
    [theme.breakpoints.down('1000')] : {
      position: 'absolute',
      height: '100%',
      width: '35% !important',
      zIndex: 5,
    },
    [theme.breakpoints.down('800')] : {
      width: '50% !important',
    },
    [theme.breakpoints.down('600')] : {
      width: '75% !important',
    },
    [theme.breakpoints.down('400')] : {
      width: '100% !important',
    }
  },
  renderColumn: {
    width: '80%',
    [theme.breakpoints.down('xl')] : {
      width: '70%',
    },
    [theme.breakpoints.down('1000')] : {
      width: '100%',
    },
  }
}));

const DocumentationEditorPage = ({ history }) => {
  const location = useLocation();
  const [selectedSubsection, setSelectedSubsection] = useState('');
  const [subsectionRender, setSubsectionRender] = useState([]);
  const [showNavbar, setShowNavbar] = useState(true)
  const classes = useStyles();
  const [hideSectorConfig, setHideSectorConfig] = useState('5vw !important'); 
  useEffect(async () => {
    try {
      if (window.innerWidth < 1001) {
        setShowNavbar(false);
      }
      if (window.innerWidth < 500) {
        setHideSectorConfig('10vw !important');
      }
      let hash = location.hash.substring(1);
      console.log(hash);
      if (!hash) {
        hash = 'editor_components'
      }
      documentationJson.sections.forEach((index) => {
        const findSection = index.subsections.find((keyvalue) => (keyvalue.id === hash));
        if (findSection) {
          setSubsectionRender(findSection);
        }
      })
      setSelectedSubsection(hash);
    } catch (error) {
      // console.log(error);
    }
  }, [location]);

  return (
    <Layout>
      <Box style={{ backgroundColor: 'rgba(243, 243, 243, 1)', width: '100%', display: 'flex', flexDirection: 'row', position: 'relative'}}>
        <Box className={classes.navColumn} sx={{width : showNavbar ? '50%' : hideSectorConfig}}>
          <SideBarDocumentation toc={documentationJson} hash={selectedSubsection} stateBox={showNavbar} setStateBox={setShowNavbar} render={false} />
        </Box>
        <Box className={classes.renderColumn}>
          <ContentDocumentation toc={subsectionRender} sourceMultimedia={'editor'} />
        </Box>
      </Box>
    </Layout>
  );
}

export default DocumentationEditorPage;