import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { IconButton, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import RenderMenuItem from './RenderMenuItem';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import '../../styles/documentation.css';

const useStyles = makeStyles((theme) => ({
  tocContainer: {
    padding: '2em 4rem',
    position: 'relative',
    overflowY: 'auto',
    maxHeight: '90vh',
    [theme.breakpoints.down('xl')]: {
      padding: '1.5em 2rem',
    }
  },
  sectionLogo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '10px',
    marginBottom: '25px',
  },
  titleStyle: {
    color: 'rgba(20, 86, 158, 1)',
    fontSize: '17px',
    fontWeight: 200,
    marginLeft: '15px',
  },
  sectionAreaContainer: {
    minHeight: '40vh',
  },
  headerSection: {
    color: 'rgba(32, 21, 73, 1)',
    fontWeight: 800,
    fontSize: '14px',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  subsectionStyle: {
    color: 'rgba(69, 69, 69, 1)',
    fontWeight: 400,
    fontSize: '14px',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      color: 'rgba(20, 86, 158, 1) !important',
    },
  },
}));

const SideBarDocumentation = ({ toc, hash, stateBox, setStateBox, render }) => {
  const classes = useStyles();
  const isBelow1000px = useMediaQuery('(max-width:1000px)');

  useEffect(() => {
    if (!isBelow1000px) {
      setStateBox(true);
    } else {
      setStateBox(false);
    }
  }, [isBelow1000px, setStateBox]);

  return (
    <>
      {isBelow1000px && (
        <IconButton
          onClick={() => setStateBox(!stateBox)}
          sx={{
            position: 'fixed',
            zIndex: 6,
            marginTop: '10px',
            left: '10px',
            transform: stateBox ? 'scaleX(-1)' : 'scaleX(1)',
          }}
          className="arrowStyleForMenu"
        >
          <DoubleArrowIcon />
        </IconButton>
      )}
      <Box
        className={classes.tocContainer}
        sx={{
          display: stateBox ? 'block' : 'none',
          transition: 'transform 0.3s ease',
          transform: stateBox ? 'translateX(0)' : 'translateX(-100%)',
          position: { xs: 'absolute', md: 'relative' },
          left: 0,
          top: 0,
          width: { xs: '250px', md: 'auto' },
          backgroundColor: 'rgba(237, 242, 252, 1)',
        }}
      >
        <Box component="div" className={classes.sectionLogo}>
          <Box component="img" src="/logo_doc.png" alt="logo" width={50} />
          <Box component="div" className={classes.titleStyle}>
            Documentación
          </Box>
        </Box>
        <Box className={classes.sectionAreaContainer}>
          {toc.sections.map((section) => (
            <RenderMenuItem hash={hash} section={section} key={section.id} render={render} />
          ))}
        </Box>
      </Box>
    </>
  );
};

export default SideBarDocumentation;